import React from 'react';
import styled, { withTheme } from 'styled-components';

import SlideOne from './header-slider/slide-one';


const HeaderSliderWrapper = styled.section`
  position: relative;
  overflow: hidden;
  color: #fff;
  font-size: 20px;
  height: calc(100vh - 91px);
  min-height: 700px;

  @media (min-width: 768px) and (max-width: 1200px) {
    height: auto;
    min-height: auto;
  }

  @media (max-width: 767px) {
    height: calc(100vh - 91px);
  }
`

const HeaderSliderInnerWrapper = styled.div`
  z-index: -50;
`

const HeaderSlider = () => {
  return (
    <HeaderSliderWrapper id="slider">
      <HeaderSliderInnerWrapper>
        <SlideOne />
      </HeaderSliderInnerWrapper>
    </HeaderSliderWrapper>
  )
}

export default withTheme(HeaderSlider)