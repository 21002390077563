import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled, { withTheme } from 'styled-components';
import { Container, Row, Col } from 'reactstrap'

import Icon from '../icon'
import { Section } from '../section';
import ListCardIcons from '../list-card-icons';

import Favicon from '../../images/favicon.png'


const AdvantagesSection = styled(Section)`
  padding-top: 30px;
  padding-bottom: 30px;

  @media (max-width: 575px) {
    text-align: center;
    padding-top: 0;
  }
`

const Advantages = () => <StaticQuery
    query={graphql`
      query AdvantagesQuery {
        allMarkdownRemark (
          filter: { frontmatter: {
            page: { eq: "advantage" } }
          },
          sort: { order: ASC, fields: [frontmatter___order] }
        ) {
          edges {
            node {
              id
              html
              frontmatter {
                title
                link
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { edges } = data.allMarkdownRemark;

      return <AdvantagesSection id="about">
        <Container>
          <Row>
            <Col>
              <Icon center src={Favicon} alt="Brookfield" />
            </Col>
          </Row>
          <Row>
            {edges.map((edge) => <ListCardIcons shadow edge={edge} key={edge.node.id} />)}
          </Row>
        </Container>
      </AdvantagesSection>
    }}
  />

export default withTheme(Advantages)
