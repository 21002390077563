import styled from 'styled-components';
import { Input } from 'reactstrap';


export const FormInput = styled(Input)`
  background-color: #fff;
  ${props => (props.status ==='error' ? 'background-color: #fde9e6;' : '')}
  ${props => (props.status ==='success' ? 'background-color: #daedd0;' : '')}
  height: 43px;
  font-size: 14px;
  margin-bottom: 10px;
  border: 1px solid rgb(142, 149, 156);
  border-radius: 3px;
  &:focus {
    opacity: 0.9;
    border: 1px solid rgb(142, 149, 156);
    box-shadow: none;
  }
`