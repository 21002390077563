import styled from 'styled-components';
import {Container } from 'reactstrap';

export const SlideComponentWrapper = styled.div`
  background-image: url(${props => (props.slideBG ? props.slideBG : null)});
  background-repeat: no-repeat;
  background-size: cover;
  color: ${props => (props.slideTextColor ? props.slideTextColor : '#fff')};
  text-align: center;

  .gatsby-image-wrapper{
    @media (max-width: 767px) {
      height: 100vh;
    }
  }
`

export const SlideComponentContainer = styled(Container)`
  left: 50%;
  padding-top: 150px;
  padding-bottom: 60px;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);

  @media (min-width: 768px) and (max-width: 1200px) {
    padding-bottom: 0;
    padding-top: 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: 0;
  }

  @media (max-width: 575px) {
    padding: 0 30px;
  }
`
