import styled from 'styled-components';

export const Section = styled.section`
  padding-top: 30px;
  padding-bottom: 30px;

  @media (max-width: 575px) {
    padding-top: 40px;
    padding-bottom: 45px;
  }
`
