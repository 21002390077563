import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from 'reactstrap';
import { EqualHeight, EqualHeightElement } from 'react-equal-height/clean';
import styled, { withTheme } from 'styled-components';

import Icon from '../icon'
import { Section } from '../section';
import { ComponentHeaderTitle } from '../component-header';
import MainSlider from '../main-slider';

import TweetsIcon from '../../images/tweets-icon.svg'


const TweetsSection = styled(Section)``

const TweetsWrapper = styled.div`
  position: relative;
  font-size: 14px;
  margin-top: 25px;
  .alice-carousel__dots {
    margin-top: 60px;
    margin-bottom: 0px;
    .alice-carousel__dots-item {
      background-color: transparent;
      width: 15px;
      height: 15px;
      border: 1px solid #8c989f;
      &.__active {
        background-color: #8c989f;
      }
    }
  }

  .equal-height-JlocK {
    display:block;
    overflow:hidden;
    transition-property:height
  }
`

const TweetsItem = styled.div`
  padding: 0 10px;
`

const TweetsItemCloud = styled.div`
  position: relative;
  background-color: #f3f4f5;
  min-height: 105px;
  height: calc(100% - 30px);
  font-size: 14px;
  padding: 24px;
  margin-bottom: ${props => props.hasAvatar ? `40px` : `30px`};
  border-radius: 5px;

  &:before {
    content: "";
    position: absolute;
    left: 30px;
    bottom: -15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16px 18px 0 18px;
    border-color: #f3f4f5 transparent transparent transparent;
  }

  p {
    color: ${props => props.theme.colorThird};
    line-height: 1.2em;
    margin-bottom: 0;
  }
`

const TweetsItemAvatar = styled(GatsbyImage)`
  overflow: hidden;
  width: 40px;
  margin-left: 30px;
  border-radius: 50%;
`

const TweetsItemMember = styled(Col)`
  font-size: 14px;
`

const TweetsItemMemberName = styled.span`
  color: #191919;
`

const TweetsItemMemberPosition = styled.span`
  color: ${(props) => props.theme.colorThird};
`

const TweetsItemMemberNickname = styled.a`
  display: block;
  margin-top: 8px;
`


const Tweets = () => {
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 575,
        settings: {
          arrows: false,
          slidesToShow: 1,
        }
      }
    ]
  }

  return <StaticQuery query={graphql`query TweetsQuery {
  allMarkdownRemark(
    filter: {frontmatter: {page: {eq: "home_tweet"}, on_home: {eq: "true"}}}
    sort: {order: ASC, fields: [frontmatter___order]}
  ) {
    edges {
      node {
        id
        html
        frontmatter {
          name
          nickname
          position
        }
      }
    }
  }
  markdownRemark(frontmatter: {page: {eq: "home_tweets"}}) {
    frontmatter {
      title
      locale
      image {
        childImageSharp {
          gatsbyImageData(
            width: 210
            quality: 100
            placeholder: TRACED_SVG
            layout: CONSTRAINED
          )
        }
      }
    }
  }
}
`}
        render={data => {
          const { edges } = data.allMarkdownRemark;
          const { frontmatter } = data.markdownRemark;

          return (
            <TweetsSection id="opinions">
              <Container>
                <Row>
                  <Col>
                    <Icon center src={TweetsIcon} alt={frontmatter.title} />
                    <ComponentHeaderTitle>{frontmatter.title}</ComponentHeaderTitle>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EqualHeight>
                      <TweetsWrapper>
                        <MainSlider
                          items={edges.map((edge, i) => (
                            <TweetsItem key={edge.node.id} onDragStart={e => e.preventDefault()}>
                              <Row>
                                <Col xs={12}>
                                  <EqualHeightElement>
                                    <TweetsItemCloud
                                      hasAvatar={edge.node.frontmatter.avatar ? true : false}
                                      dangerouslySetInnerHTML={{ __html: edge.node.html }} 
                                    />
                                  </EqualHeightElement>
                                </Col>
                                <Col xs={12}>
                                  <Row>
                                    {edge.node.frontmatter.avatar && <Col xs={3}>
                                      <TweetsItemAvatar
                                        image={edge.node.frontmatter.avatar.childImageSharp.gatsbyImageData}
                                        alt={edge.node.frontmatter.name}
                                      />
                                    </Col>}
                                    <TweetsItemMember xs={{
                                        size: edge.node.frontmatter.avatar ? 9 : 11,
                                        offset: edge.node.frontmatter.avatar ? 0 : 1,
                                      }}>
                                      <TweetsItemMemberName>{edge.node.frontmatter.name} </TweetsItemMemberName>
                                      <TweetsItemMemberPosition>{edge.node.frontmatter.position}</TweetsItemMemberPosition>
                                      {edge.node.frontmatter.nickname && <TweetsItemMemberNickname href={'https://twitter.com/' + edge.node.frontmatter.nickname} target="_blank" rel="noopener">@{edge.node.frontmatter.nickname}</TweetsItemMemberNickname>}
                                    </TweetsItemMember>
                                  </Row>
                                </Col>
                              </Row>
                            </TweetsItem>))}
                          settings={settings}
                        />
                      </TweetsWrapper>
                    </EqualHeight>
                  </Col>
                </Row>
              </Container>
            </TweetsSection>
          );
        }}
      />
      }

export default withTheme(Tweets)