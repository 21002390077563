import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import styled, { withTheme } from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

import { Section } from '../section';
import { TitleMiddleH3 } from '../titles';
import { ComponentHeaderTitle, ComponentHeaderDesc } from '../component-header';
import MainSlider from '../main-slider';
import orphanRemove from '../orphan-remove-text';
import Icon from '../icon'

import LinkedInImage from '../../images/icon-linkedin.svg'
import TeamIcon from '../../images/team-icon.svg'

const TeamSection = styled(Section)`
  background-color: ${props => props.theme.colorSecondaryBG};
`

const TeamItem = styled(Col)`
  line-height: 1em;
  text-align: center;
  margin-top: 30px;

  a{
    display: inline-block;

    &:hover{
      text-decoration: none;

      &:before{
        content: none;
        display: none;
      }
    }

    img{
      height: auto;
      width: 24px;
    }
  }
`

const TeamItemImage = styled(GatsbyImage)`
  overflow: hidden;
  border-radius: 50%;
  margin-left: 5%;
  margin-right: 5%;

  picture {
    img {
      border-radius: 50%;
    }
  }
`

const TeamItemTitle = styled(TitleMiddleH3)`
  margin-top: 30px;
  margin-bottom: 5px;
`

const TeamItemPosition = styled.p`
  color: ${props => props.theme.colorThird};
`


const Team = () => {
  const handleOnDragStart = e => e.preventDefault();
  
  return (
    <StaticQuery
      query={graphql`query HomeTeamQuery {
  allMarkdownRemark(
    filter: {frontmatter: {page: {eq: "team_member"}, on_home: {eq: "true"}}}
    sort: {order: ASC, fields: [frontmatter___order]}
  ) {
    edges {
      node {
        id
        frontmatter {
          name
          position
          linkedin
          image {
            childImageSharp {
              gatsbyImageData(width: 210, quality: 100, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
  markdownRemark(frontmatter: {page: {eq: "home_team"}}) {
    html
    frontmatter {
      title
      locale
      image {
        childImageSharp {
          gatsbyImageData(
            width: 210
            quality: 100
            placeholder: TRACED_SVG
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
}
`}
      render={data => {
        const { edges } = data.allMarkdownRemark;
        const { html, frontmatter } = data.markdownRemark;
        const settings = {
          arrows: true,
          dots: true,
          slidesToShow: 4,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2
              },
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1
              }
            },
          ]
          
        };

        const items = edges.map((edge) => <TeamItem key={edge.node.id} onDragStart={handleOnDragStart}>
          <TeamItemImage
            image={edge.node.frontmatter.image.childImageSharp.gatsbyImageData}
            alt={edge.node.frontmatter.name}
          />
          <TeamItemTitle>{edge.node.frontmatter.name}</TeamItemTitle>
          <TeamItemPosition>{edge.node.frontmatter.position}</TeamItemPosition>
      
          {edge.node.frontmatter.linkedin && <a href={edge.node.frontmatter.linkedin} target="_blank" rel="noopener noreferrer">
            <img src={LinkedInImage} alt={edge.node.frontmatter.name} />
          </a>}
        </TeamItem>)

        return <TeamSection id="team">
          <Container>
            <Row>
              <Col>
                <Icon center src={TeamIcon} alt={frontmatter.title} />
                <ComponentHeaderTitle>{frontmatter.title}</ComponentHeaderTitle>
                <ComponentHeaderDesc dangerouslySetInnerHTML={{ __html: orphanRemove(html) }} />
              </Col>
            </Row>
            <Row>
              <Col>
                <MainSlider
                  items={items}
                  settings={settings}
                />
              </Col>
            </Row>
          </Container>
        </TeamSection>
      }}
    />
  );
}

export default withTheme(Team)
