import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import styled, { withTheme } from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

import { Section } from '../section';


const ClientLogosSection = styled(Section)`
  padding-top: 40px;
  padding-bottom: 40px;
  border-top: 1px solid #e8eaec;
  @media (max-width: 767px) {
    padding-bottom: 35px
  }

  .row{
    & > div{
      text-align: center;

      @media (max-width: 768px) {
        margin-bottom: 30px;
      }
    }

    .gatsby-image-wrapper{
      margin-left: auto;
      margin-right: auto;
    }
  }
`

const ClientLogosImage = styled(GatsbyImage)`
  height: auto;
  max-width: 200px;
  top: 50%;
  transform: translateY(-50%);
  vertical-align: middle;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }

  img{
    vertical-align: middle;
  }
`

const ClientLogos = () => {
  return (
    <StaticQuery
      query={graphql`query HomeClientLogosQuery {
  allMarkdownRemark(
    filter: {frontmatter: {page: {eq: "home_client_logo"}, on_home: {eq: "true"}}}
    sort: {order: ASC, fields: [frontmatter___order]}
  ) {
    edges {
      node {
        id
        frontmatter {
          name
          image {
            childImageSharp {
              gatsbyImageData(
                width: 200
                quality: 100
                placeholder: TRACED_SVG
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
}
`}
      render={data => {
        const { edges } = data.allMarkdownRemark;

        return (
          <ClientLogosSection>
            <Container>
              <Row>
                {edges.map(({node}) => <Col xs={6} md={3} key={node.id}>
                  <ClientLogosImage
                    image={node.frontmatter.image.childImageSharp.gatsbyImageData}
                    alt={node.frontmatter.name}
                  />
                </Col>)}
              </Row>
            </Container>
          </ClientLogosSection>
        );
      }}
    />
  );
}

export default withTheme(ClientLogos)