import { GatsbyImage } from "gatsby-plugin-image";
import styled from 'styled-components';
import { Col } from 'reactstrap';

import { Section } from '../../section';
import { TitleLargeH2 } from '../../titles';
import { ExternalButtonLink } from '../../buttons';


export const OfficesSection = styled(Section)`
  background-color: ${props => props.theme.colorSecondaryBG};
  padding-top: 35px;
  padding-bottom: 35px;

  @media (max-width: 575px) {
    text-align: center;
  }
`

export const OfficesCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const OfficesWrapper = styled.div`
  width: 100%;
  text-align: center;
`

export const OfficesTitle = styled(TitleLargeH2)`
  margin-top: 0;
  @media (max-width: 767px) {
    margin-top: 35px;
  }
`

export const OfficesLogo = styled(GatsbyImage)`
  width: ${props => props.percentWidth};
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
`

export const OfficesDesc = styled.div`
  margin-bottom: 30px;
`

export const OfficesButtonWrapper = styled.div`
  display: inline-block;
`

export const OfficesButtonLink = styled(ExternalButtonLink)`
  @media (max-width: 575px) {
    width: 100%;
  }
`

export const OfficesButtonSubDesc = styled.span`
  display: block;
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
`
