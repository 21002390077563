import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import styled, { withTheme } from 'styled-components';
import axios from 'axios';

import { Container, Row, Col, Form, FormGroup } from 'reactstrap';

import { Section } from '../section';
import { TitleLargeH2 } from '../titles';
import { FormInput } from '../inputs';
import { MainButton } from '../buttons';
import orphanRemove from '../orphan-remove-text';
import SubmitModalForm from './../submit-modal-form';


const ContactFormSection = styled(Section)`
  background-color: ${props => props.theme.colorPrimaryBG};
  color: #fff;
  font-size: 18px;
  text-align: center;
  padding-top: 75px;
  padding-bottom: 85px;
  @media (max-width: 575px) {
    font-size: 16px;
  }
`

const ContactFormTitle = styled(TitleLargeH2)`
  color: #fff;
  font-size: 30px;
  @media (max-width: 575px) {
    font-size: 28px;
  }
`

const ContactFormInput = styled(FormInput)``

const ContactFormButtonSubmit = styled(MainButton)`
  @media (max-width: 575px) {
    width: 100%;
  }
`

const ContactFormWrapper = styled(Form)`
  padding-top: 30px;
`

const ContactFormAgreement = styled(Col)`
  font-size: 12px;
  text-align: left;
  margin-bottom: 20px;
  p {
    margin-bottom: 8px;
  }
`


class StartFree extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      formEmail: null,
      formPhone: null,
      formMessage: "Jesteśmy zainteresowani najmem biura.",
      hasError: false,
      showSubmittedForm: false
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    let emailValue = this.state.formEmail || null,
        phoneValue = this.state.formPhone || null,
        messageValue = this.state.formMessage || null

    let formSubmit = false
    if ((phoneValue || emailValue) && messageValue) {
      formSubmit = true
    }

    if (!formSubmit) {
      this.setState({formError: true})
    } else {
      this.setState({formError: false})
      axios.post('//bo.officelist.pl/api/v1/contact_messages/', {
        email: emailValue,
        phone: phoneValue,
        message: messageValue,
        source: "brookfield.pl"
      }, {headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': 'pl'
      }})
      .then(response => {
        this.setState({
          showSubmittedForm: true
        })

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "Contact",
          type: "Send form 2"
        })
      })
    }
  }

  render() {
    return <StaticQuery
      query={graphql`
        query ContactFormQuery {
          markdownRemark(frontmatter: { page: { eq: "contact_form" } }) {
            html
            frontmatter {
              title
              locale
            }
          }
        }
      `}
      render={(data) => {
        const { html, frontmatter } = data.markdownRemark;
        return <ContactFormSection id="contactForm">
          <Container>
            <Row>
              <Col xs={12} sm={{ size: 10, offset: 1 }} md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
                <ContactFormTitle>{frontmatter.title}</ContactFormTitle>
                <div dangerouslySetInnerHTML={{ __html: orphanRemove(html) }} />
              </Col>
              <Col xs={12} sm={{ size: 10, offset: 1 }} md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
                <ContactFormWrapper
                    ref={ref => this.form = ref}
                    onSubmit={this.handleSubmit.bind(this)}>
                  <Row form>
                    <Col md={6}>
                      <ContactFormInput
                        ref={ref => this.inputEmail = ref}
                        type="email"
                        name="contact_email"
                        placeholder="E-mail"
                        aria-label="Adres e-mail"
                        value={this.state.formEmail}
                        onChange={e => this.setState({formEmail: e.currentTarget.value})}
                        status={this.props.emailStatus} />
                    </Col>
                    <Col md={6}>
                      <ContactFormInput
                        ref={ref => this.inputPhone = ref}
                        type="tel"
                        name="contact_phone"
                        placeholder="Telefon"
                        aria-label="Numer telefonu"
                        value={this.state.formPhone}
                        onChange={e => this.setState({formPhone: e.currentTarget.value})}
                        status={this.props.phoneStatus} />
                    </Col>
                    <Col md={12}>
                      <ContactFormInput
                        ref={ref => this.inputMessage = ref}
                        type="textarea"
                        aria-label="Treść wiadomości"
                        status={this.props.messageStatus}
                        rows="3"
                        name="contact_message"
                        value={this.state.formMessage}
                        onChange={e => this.setState({forMmessage: e.currentTarget.value})} />
                    </Col>
                    <ContactFormAgreement xs={12}>
                      <p>Na pytania odpowiadamy błyskawicznie. Nie wysyłamy spamu.</p>
                      <p>Informacja o polityce bezpieczeństwa danych osobowych na stronie brookfield.pl.</p>
                    </ContactFormAgreement>
                    <Col xs={12}>
                      <FormGroup>
                        <ContactFormButtonSubmit
                            type="submit"
                            btncolor={'#21b28a'}
                            btnbordercolor={'#4aa6fb'}
                            btncolorhover={'#0a8c0b'}>
                          <FormattedMessage id="CONTACT_FORM_SEND" />
                        </ContactFormButtonSubmit>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContactFormWrapper>
              </Col>
            </Row>
          </Container>
          {this.state.showSubmittedForm && <SubmitModalForm handleShowSubmittedForm={() => this.setState({showSubmittedForm: !this.state.showSubmittedForm})} />}
        </ContactFormSection>
      }} />
  }
}

export default withTheme(StartFree)
