import styled from 'styled-components';

import { TitleLargeH2 } from './titles';
import Img from 'gatsby-image';

export const ComponentHeaderTitle = styled(TitleLargeH2)`
  text-align: center;
  text-transform: uppercase;
`

export const ComponentHeaderIcon = styled(Img)`
  width: 55px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;

  @media (max-width: 575px) {
    margin-bottom: 25px;
  }

  img {
    width: 100%;
  }
`

export const ComponentHeaderDesc = styled.div`
  font-size: 18px;
  text-align: center;
  padding-left: 25%;
  padding-right: 25%;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media (max-width: 575px) {
    padding-left: 5%;
    padding-right: 5%;
  }
`
