import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';
import { Container, Row } from 'reactstrap';

import { OfficesSection, OfficesCol, OfficesWrapper, OfficesTitle, OfficesLogo, OfficesButtonWrapper, OfficesButtonLink } from './offices/offices';


const OfficesFirst = (props) => <React.Fragment>
  <StaticQuery
    query={graphql`query OfficesFirstQuery {
  markdownRemark(frontmatter: {page: {eq: "offices-first"}}) {
    html
    frontmatter {
      title
      locale
      logo_percent_width
      button_sub_title
      image {
        childImageSharp {
          gatsbyImageData(
            width: 590
            quality: 100
            placeholder: TRACED_SVG
            layout: CONSTRAINED
          )
        }
      }
      logo {
        childImageSharp {
          gatsbyImageData(
            width: 420
            quality: 100
            placeholder: TRACED_SVG
            layout: CONSTRAINED
          )
        }
      }
    }
  }
}
`}
    render={data => {
      const { frontmatter } = data.markdownRemark;

      return (
        <OfficesSection id="offices">
          <Container>
            <Row>
              <OfficesCol md={6}>
                <OfficesWrapper>
                  <GatsbyImage image={frontmatter.image.childImageSharp.gatsbyImageData} />
                </OfficesWrapper>
              </OfficesCol>
              <OfficesCol md={6}>
                  <OfficesWrapper>
                    <OfficesLogo fluid={frontmatter.logo.childImageSharp.gatsbyImageData} percentWidth={'45%'} />
                    <OfficesTitle dangerouslySetInnerHTML={{ __html: frontmatter.title }} />
                    <OfficesButtonWrapper>
                      <OfficesButtonLink
                        href={'https://www.officelist.pl/'}
                        target="_blank"
                        btncolor={'#1b98e0'}
                      >
                        <FormattedMessage id="OFFICES_CHECK_NOW" />
                      </OfficesButtonLink>
                    </OfficesButtonWrapper>
                  </OfficesWrapper>
              </OfficesCol>
            </Row>
          </Container>
        </OfficesSection>
      );
    }}
  />
</React.Fragment>

export default withTheme(OfficesFirst)
