import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import styled, { withTheme } from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

import { Section } from '../section';
import { TitleMiddleH3 } from '../titles';
import { InternalButtonLinkArrow } from '../buttons';
import { ComponentHeaderTitle, ComponentHeaderDesc } from '../component-header';
import orphanRemove from '../orphan-remove-text';
import Icon from '../icon'

import ServicesIcon from '../../images/services/icon.svg'
import ServicesIcon1 from '../../images/services/1.svg'
import ServicesIcon2 from '../../images/services/2.svg'
import ServicesIcon3 from '../../images/services/3.svg'
import ServicesIcon4 from '../../images/services/4.svg'
import ServicesIcon5 from '../../images/services/5.svg'
import ServicesIcon6 from '../../images/services/6.svg'
import ServicesIcon7 from '../../images/services/7.svg'
import ServicesIcon8 from '../../images/services/8.svg'


const ServicesSection = styled(Section)``

const ServicesItem = styled(Col)`
  text-align: center;
  margin-top: 30px;
`

const ServicesItemDesc = styled.div``


const Services = () => {

  const getServiceIcon = num => {
    switch (num) {
      case 1: return ServicesIcon1;
      case 2: return ServicesIcon2;
      case 3: return ServicesIcon3;
      case 4: return ServicesIcon4;
      case 5: return ServicesIcon5;
      case 6: return ServicesIcon6;
      case 7: return ServicesIcon7;
      case 8: return ServicesIcon8;
      default: return ServicesIcon;
    }
  }

  return (<StaticQuery
    query={graphql`
      query HomeServicesQuery {
        allMarkdownRemark (
          filter: { frontmatter: {
              page: { eq: "service" },
              on_home: { eq: "true" }
            }
          },
          sort: { order: ASC, fields: [frontmatter___order] }
        ) {
          edges {
            node {
              id
              html
              frontmatter {
                name
                link
              }
            }
          }
        },
        markdownRemark(frontmatter: { page: { eq: "services" } }) {
          html
          frontmatter {
            title
            locale
            image {
              childImageSharp {
                fluid(maxWidth: 210, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { edges } = data.allMarkdownRemark;
      const { html, frontmatter } = data.markdownRemark;

      return <ServicesSection id="services">
        <Container>
          <Row>
            <Col>
              <Icon center src={ServicesIcon} alt={frontmatter.title} />
              <ComponentHeaderTitle>{frontmatter.title}</ComponentHeaderTitle>
              <ComponentHeaderDesc dangerouslySetInnerHTML={{ __html: orphanRemove(html) }} />
            </Col>
          </Row>
          <Row>
            {edges.map((edge, i) => <ServicesItem sm={6} md={3} key={edge.node.id}>
              <Icon src={getServiceIcon(i + 1)} alt={edge.node.frontmatter.name} />
              <TitleMiddleH3>{edge.node.frontmatter.name}</TitleMiddleH3>
              <ServicesItemDesc dangerouslySetInnerHTML={{ __html: orphanRemove(edge.node.html) }} />
              {edge.node.link && <InternalButtonLinkArrow to={edge.node.link}>
                <FormattedMessage id="SERVICES_LEARN_MORE" />
              </InternalButtonLinkArrow>}
            </ServicesItem>)}
          </Row>
        </Container>
      </ServicesSection>
    }}
  />

  )
}

export default withTheme(Services)