import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import styled, { withTheme } from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

import { Section } from '../section';
import { ComponentHeaderTitle, ComponentHeaderDesc } from '../component-header';
import MainSlider from '../main-slider';
import orphanRemove from '../orphan-remove-text';
import Icon from '../icon'

import AreaIcon from '../../images/projects/area.svg'
import LocationIcon from '../../images/projects/location.svg'
import ProjectsIcon from '../../images/projects-icon.svg'
import TypeIcon from '../../images/projects/type.svg'


const LastProjectsSection = styled(Section)`
  background-color: ${props => props.theme.colorSecondaryBG};

  @media (max-width: 575px) {
    padding-top: 55px;
  }
`

const LastProjectsItem = styled.div`
  padding: 0 10px;
`


const InfoP = styled.p`
  color: #000;
  margin-bottom: 0;
  padding-left: 20px;
  position: relative;

  ${props => props.type === "area" ? `
    margin-top: 10px;
  ` : ``}

  &:before{
    background-position: center center;
    background-repeat: no-repeat;
    content: '';
    display: block;
    height: 12px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;

    ${props => props.type === "area" ? `
      background-image: url(${AreaIcon});
    ` : ``}

    ${props => props.type === "location" ? `
      background-image: url(${LocationIcon});
    ` : ``}

    ${props => props.type === "type" ? `
      background-image: url(${TypeIcon});
    ` : ``}
  }
`


const ImgWrapper = styled.a`
  display: block;

  &:before{
    display: none !important;
  }

  &:hover{
    p{
      opacity: 1;
    }
  }

  p{
    background-color: rgba(41, 147, 251, .7);
    bottom: 0;
    color: #fff;
    left: 0;
    font-size: 2em;
    margin: 0;
    opacity: 0;
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 0;
    transition: ease .2s all;
    text-align: center;

    span{
      display: inline-block;
      left: 2rem;
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`


const sliderItems = (edges) => {
  const handleOnDragStart = e => e.preventDefault();

  return edges.map((edge, i) => <LastProjectsItem key={edge.node.id} onDragStart={handleOnDragStart}>
    <ImgWrapper>
      <GatsbyImage
        image={edge.node.frontmatter.image.childImageSharp.gatsbyImageData}
        alt={edge.node.frontmatter.name}
      />
      <p><span dangerouslySetInnerHTML={{__html: edge.node.frontmatter.name}} /></p>
    </ImgWrapper>
    <InfoP type="area" dangerouslySetInnerHTML={{__html: edge.node.frontmatter.area}} />
    <InfoP type="location">{edge.node.frontmatter.location}</InfoP>
    <InfoP type="type">{edge.node.frontmatter.type_work}</InfoP>
  </LastProjectsItem>);
}

const LastProjects = (props) => {
  const settings = {
    arrows: true,
    infinite: false,
    dots: true,
    slidesToShow: 2,
    slidesToSCroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 599,
        settings: {
          arrows: false,
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <StaticQuery
      query={graphql`query LastProjectsQuery {
  allMarkdownRemark(
    filter: {frontmatter: {page: {eq: "last_project"}, on_home: {eq: "true"}}}
    sort: {order: DESC, fields: [frontmatter___order]}
  ) {
    edges {
      node {
        id
        frontmatter {
          name
          area
          location
          type_work
          image {
            childImageSharp {
              gatsbyImageData(width: 450, quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  markdownRemark(frontmatter: {page: {eq: "last_projects"}}) {
    html
    frontmatter {
      title
      locale
      image {
        childImageSharp {
          gatsbyImageData(
            width: 210
            quality: 100
            placeholder: TRACED_SVG
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
}
`}
      render={data => {
        const { edges } = data.allMarkdownRemark;
        const { html, frontmatter } = data.markdownRemark;

        return <LastProjectsSection>
          <Container>
            <Row>
              <Col>
                <Icon center src={ProjectsIcon} alt={frontmatter.title} />
                <ComponentHeaderTitle>{frontmatter.title}</ComponentHeaderTitle>
                <ComponentHeaderDesc dangerouslySetInnerHTML={{ __html: orphanRemove(html) }} />
              </Col>
            </Row>
            <Row>
              <Col>
                <MainSlider
                  items={sliderItems(edges)}
                  settings={settings}
                />
              </Col>
            </Row>
          </Container>
        </LastProjectsSection>
      }}
    />
  );
}

export default withTheme(LastProjects)
