import styled from 'styled-components'

export default styled.img`
  height: 55px;
  margin-bottom: 15px;
  width: auto;

  ${props => props.center ? `
    display: block;
    margin-left: auto;
    margin-right: auto;
  ` : ``}
`
