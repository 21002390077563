import React from 'react';
import Img from 'gatsby-image';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';

import { TitleMiddleH2 } from './titles';
import { InternalButtonLinkArrow } from './buttons';
import orphanRemove from './orphan-remove-text';



const ListCardIconsItem =styled(Col)`
  margin-top: 50px;
`

const ListCardIconsItemImage = styled(Img)`
  margin-bottom: 30px;
  @media (max-width: 991px) {
    width: 70px;
    margin-left: auto;
    margin-right: auto;
  }
`

const ListCardIconsItemTitle = styled(TitleMiddleH2)`
  @media (min-width: 768px) and (max-width: 991px) {
    text-align: center;
  }
  @media (max-width: 575px) {
    text-align: center;
  }
`

const ListCardIconsItemDesc = styled.div`
  margin-bottom: 20px;
`

const ListCardIconsButtonLinkWrapper = styled.div`
  @media (max-width: 575px) {
    text-align: center;
  }
`

const ListCardNumber = styled.p`
  color: #2c72b5;
  font-size: 5em;
  font-weight: bold;
  line-height: .8em;
  margin: 0;
  position: relative;
  vertical-align: middle;
  opacity: .7;
  text-align: right;

  @media (max-width: 575px) {
    font-size: 4em;
  }
`

const ListCardIcons = (props) => {
  let withImage = (props.edge.node.frontmatter.image || props.numbers)

  return (
    <ListCardIconsItem md={4}>
      <Row>
        {withImage && <Col sm={4} md={12} lg={4} xl={3}>
          {props.edge.node.frontmatter.image && <ListCardIconsItemImage fluid={props.edge.node.frontmatter.image.childImageSharp.fluid} />}
          {props.numbers && <ListCardNumber>{props.numbers}</ListCardNumber>}
        </Col>}
        <Col
            sm={withImage ? 8 : 12}
            md={12}
            lg={withImage ? 8 : 12}
            xl={withImage ? 9 : 12}>
          <ListCardIconsItemTitle>{props.edge.node.frontmatter.title}</ListCardIconsItemTitle>
          <ListCardIconsItemDesc dangerouslySetInnerHTML={{ __html: orphanRemove(props.edge.node.html) }} />

          {props.edge.node.frontmatter.link && <ListCardIconsButtonLinkWrapper>
            <InternalButtonLinkArrow to={'/'}>
              <FormattedMessage id="ADVANTAGES_LEARN_MORE" />
            </InternalButtonLinkArrow>
          </ListCardIconsButtonLinkWrapper>}
        </Col>
      </Row>
    </ListCardIconsItem>
  )
}

export default ListCardIcons
