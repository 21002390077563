import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody } from 'reactstrap';

import { MainButton } from './buttons';


const SubmitModalFormWrapper = styled(Modal)`
  color: #fff;
  margin-top: 10rem;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  .modal-content {
    background-color: #2993fb;
    border-radius: 0;
  }
`

const SubmitModalFormBody = styled(ModalBody)``

const SubmitModalFormTextWrapper = styled.div`
  text-align: center;
  padding-top: 15px;
  padding-bottom: 30px;
`

const SubmitModalFormButtonWrapper = styled.div`
  text-align: center;
`

const SubmitModalFormButtonClose = styled(MainButton)`
  min-width: 140px;
  padding-top: 8px;
  padding-bottom: 8px;
`

class SubmitModalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
    this.props.handleShowSubmittedForm();
  }

  render() {
    return (
      <SubmitModalFormWrapper isOpen={this.state.modal} toggle={this.toggle}>
        {/* <ModalHeader toggle={this.toggle}>Modal title</ModalHeader> */}
        <SubmitModalFormBody>
          <SubmitModalFormTextWrapper><FormattedMessage id="SUBMIT_MODAL_FORM_TEXT" /></SubmitModalFormTextWrapper>
          <SubmitModalFormButtonWrapper>
            <SubmitModalFormButtonClose
              btncolor={'#21b28a'}
              btnbordercolor={'#4aa6fb'}
              btncolorhover={'#0a8c0b'}
              onClick={this.toggle}>
              <FormattedMessage id="SUBMIT_MODAL_FORM_BUTTON_CLOSE" />
            </SubmitModalFormButtonClose>
          </SubmitModalFormButtonWrapper>
        </SubmitModalFormBody>
      </SubmitModalFormWrapper>
    );
  }
}

export default SubmitModalForm;
