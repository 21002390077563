import React from 'react';
import styled from 'styled-components';
//import AliceCarousel from 'react-alice-carousel';
//import 'react-alice-carousel/lib/alice-carousel.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import SliderArrowLeftGray from '../images/icons/slider-arrow-left-gray.svg';
import SliderArrowRightGray from '../images/icons/slider-arrow-right-gray.svg';


const MainSliderWrapper = styled.div`
  position: relative;
  font-size: 14px;

  .slick-arrow {
    position: absolute;
    width: initial;
    top: 45%;
    padding: 0;
    margin-top: -60px;

    &:before {
      background-repeat: no-repeat;
      content: '';
      display: block;
      width: 16px;
      height:34px;
    }

    &.slick-next {
      @media (max-width: 424px) {
        right: 10px;
      }

      @media (min-width: 425px) and (max-width: 575px) {
        right: 25px;
      }

      &:before {
        background-image: url(${SliderArrowRightGray});
      }
    }
    
    &.slick-prev {
      @media (max-width: 424px) {
        left: 10px;
      }

      @media (min-width: 425px) and (max-width: 575px) {
        left: 25px;
      }

      &:before {
        background-image: url(${SliderArrowLeftGray});
      }
    }
  }

  .slick-dots {
    margin-top: 60px;
    margin-bottom: 0px;
    position: static;

    @media (max-width: 575px) {
      margin-top: 15px;
    }

    li {
      
      &.slick-active {
        button {
          &:before {
            background-color: #8c989f;
          }
        }
      }

      button {
        &:before {
          background-color: transparent;
          border: 1px solid #8c989f;
          border-radius: 50%;
          content: '';
          width: 15px;
          height: 15px;
        }
      }
    }
  }
`

const MainSlider = ({ items, settings }) => {
  return <MainSliderWrapper>
    <Slider {...settings}>
      {items}
    </Slider>
  </MainSliderWrapper>
}

export default MainSlider
