import React from 'react';
import axios from 'axios';
import { graphql, StaticQuery } from 'gatsby';
import styled, { withTheme } from 'styled-components';
import { Row, Col } from 'reactstrap';
import { GatsbyImage } from "gatsby-plugin-image";
import { Link as LinkScroll } from 'react-scroll'

import { SlideComponentWrapper, SlideComponentContainer } from './slide-components';
import orphanRemove from '../../orphan-remove-text';
import SubmitModalForm from '../../submit-modal-form';


const AboutLinkScroll =  styled(LinkScroll)`
  bottom: 15px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: .7em;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);

  @media (max-width: 820px) {
    display: none;
  }

  &:after{
    bottom: -5px;
    content: '';
    left: 50%;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #ffffff transparent transparent transparent;
    transform: translateX(-50%);
    transition: ease .2s all;
  }

  &:hover{
    color: #fff;
    text-decoration: none;

    &:before{
      display: none;
    }

    &:after{
      bottom: -10px;
    }
  }
`


const FormWrapper = styled.div`
  background: #fff;
  border-radius: 3px;
  display: inline-block;
  padding-right: .3em;

  ${props => props.hasError ? `
    border: 2px solid red;
  ` : `
    border: 2px solid #4aa6fb;
  `}

  @media (max-width: 510px) {
    padding: .3em;
  }

  button{
    background: #21b28a;
    border: 0;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    padding: .3em 3em;
    transition: ease .2s all;

    @media (max-width: 510px) {
      width: 100%;
    }

    @media (min-width: 511px) and (max-width: 992px) {
      font-size: .85em;
      padding: .3em 1em;
      width: 30%;
    }

    &:hover{
      background-color: #0a8c0b;
    }
  }

  input{
    border: 0;
    outline: none;
    margin-bottom: .3em;
    margin-top: .3em;
    padding: .2em 1em;

    @media (max-width: 510px) {
      width: 100%;
    }

    @media (min-width: 511px) and (max-width: 992px) {
      font-size: .85em;
      padding: .2em .5em;
      width: 35%;
    }

    &:first-child{
      border-right: 1px solid #8c989f;

      @media (max-width: 510px) {
        border-right: 0;
      }
    }
  }

  p{
    font-size: .8em;
    margin-top: 1em;

    @media (max-width: 992px) {
      font-size: .5em;
      line-height: .7em;
    }
  }
`

const HeaderSliderTitle = styled.h2`
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;

  @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 32px;
  }

  @media (max-width: 767px) {
    font-size: 35px;
  }
  @media (max-width: 575px) {
    font-size: 28px;
  }
`

const HeaderSliderSubTitle = styled.h3`
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 25px;
  text-transform: uppercase;

  @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 32px;
  }

  @media (max-width: 767px) {
    font-size: 35px;
  }
  @media (max-width: 575px) {
    font-size: 28px;
  }
`

const HeaderSliderButtonWrapper = styled.div`
  margin-top: 40px;

  p{
    font-size: .9em;
    margin-top: .5em;

    @media (max-width: 992px) {
      font-size: .7em;
      line-height: 1em;
    }
  }
`


class SlideOne extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      formError: false,
      showSubmittedForm: false
    }
  }

  handleFormSubmit(e) {
    e.preventDefault()
    let phoneValue = this.inputPhone.value || null,
        emailValue = this.inputEmail.value || null
    if (!phoneValue && !emailValue) {
      this.setState({formError: true})
    } else {
      this.setState({formError: false})
      axios.post('https://bo.officelist.pl/api/v1/contact_messages/', {
        email: emailValue,
        phone: phoneValue,
        message: "Proszę o kontakt w sprawie najmu biura",
        source: "brookfield.pl"
      }, {headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': 'pl'
      }})
      .then(response => {
        this.form.reset()
        this.setState({
          showSubmittedForm: true
        })

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "Contact",
          type: "Send form 1"
        })
      })
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`query SlideOneQuery {
          markdownRemark(frontmatter: {page: {eq: "header_slide_one"}}) {
            html
            frontmatter {
              locale
              title
              subtitle
              afterForm
              formEmail
              formPhone
              formButton
              nextLink
              slide_bg {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
        `}
        render={data => {
          const { html, frontmatter } = data.markdownRemark;
          return (
            <SlideComponentWrapper slideTextColor={'#fff'}>
                <GatsbyImage
                  image={frontmatter.slide_bg.childImageSharp.gatsbyImageData}
                  className={'slideBG'}
                  alt={frontmatter.title}
                />
                <SlideComponentContainer>
                  <Row>
                    <Col md={{ size: 10, offset: 1}}>
                      <HeaderSliderTitle>{frontmatter.title}</HeaderSliderTitle>
                      <HeaderSliderSubTitle>{frontmatter.subtitle}</HeaderSliderSubTitle>
                      <div dangerouslySetInnerHTML={{ __html: orphanRemove(html) }} />
                      <HeaderSliderButtonWrapper>
                        <form ref={ref => this.form = ref} onSubmit={this.handleFormSubmit.bind(this)}>
                          <FormWrapper  hasError={this.state.formError}>
                            <input
                              ref={ref => this.inputEmail = ref}
                              type="email"
                              name="email"
                              placeholder={frontmatter.formEmail} />
                            <input
                              ref={ref => this.inputPhone = ref}
                              type="tel"
                              name="phone"
                              placeholder={frontmatter.formPhone} />
                            <button type="submit">{frontmatter.formButton}</button>
                          </FormWrapper>
                          <p>{frontmatter.afterForm}</p>
                        </form>
                      </HeaderSliderButtonWrapper>
                    </Col>
                  </Row>

                  {this.state.showSubmittedForm && <SubmitModalForm handleShowSubmittedForm={() => this.setState({showSubmittedForm: !this.state.showSubmittedForm})} />}
                </SlideComponentContainer>
                <AboutLinkScroll to="about" smooth>{frontmatter.nextLink}</AboutLinkScroll>
              </SlideComponentWrapper>
          );
        }}
      />
    );
  }
}

export default withTheme(SlideOne)
